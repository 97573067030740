import React from "react";
import "../../../css/chainList.css";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {NglContext} from "../NglContext";
import {useState, useContext} from "react";

const CopyCon = ({chainName, comp}) => {
    const {
        ngl: {comparray, chaindatas},
    } = useContext(NglContext);

    const chainData = chaindatas[comp]
    const component = comparray[comp]

    const handleChange = () => {
        const len = Object.keys(chainData).filter((chain) => {
            return chain.includes(chainName);
        });


        const l = len.length - 1;
        chainData[`${chainName}_Copy_${l}`] = {
            id: chainName,
            name: `${chainName}_Copy_${l}`,
            visible: chainData[chainName].visible,
            color: chainData[chainName].color,
            depiction: chainData[chainName].depiction,
            description: `${chainName}_Copy_${l}`,
            selection: chainData[chainName].selection,
            opacity: chainData[chainName].opacity,
            autoselection: chainData[chainName].autoselection,
            manualselection: chainData[chainName].manualselection,
        };
        component.addRepresentation(chainData[chainName].depiction, {
            sele: chainData[chainName].selection.map(item=>`(:${item} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection})`).join(" ; "),
            color: chainData[chainName].color,
            opacity: 1,
            visible: true,
            name: `${chainName}_Copy_${l}`,
        });
    };

    return <FileCopyIcon onClick={handleChange}/>;
};

export default CopyCon;
