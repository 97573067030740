//https://github.com/dharmelolar/material-UI-navbar/blob/master/src/component/Navbar.js

import React from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import menu_items from "../data/menu_items.json";
import version from "../data/version.json";

const useStyles = makeStyles((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(2),
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
        color:  "#6495ed",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: theme.spacing(10),
        "&:hover": {
            color: "pink",
            borderBottom: "pink",
        },
    },
    palette: {
        primary:  '#ffffff',
            }
}));

function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
<div style={{backgroundColor: "#111111"}}>
        <AppBar position="static" color="transparent">
            <CssBaseline />
            <Toolbar>
                <Typography variant="h5" className={classes.logo}>
                    moltube.com <i style={{'font-size':'0.5em'}}>{version.version}</i>
                </Typography>

                {isMobile ? (
                    <DrawerComponent />
                ) : (
                    <div className={classes.navlinks}>
                    {menu_items.map((item) => {
                        return (
                            <div className={classes.navlinks}>
                                <Link to={item.link} className={classes.link}>
                                    {item.name}
                                </Link>
                            </div>
                            )})}
                    </div>
                    )}
            </Toolbar>

        </AppBar>

</div>
    );
}
export default Navbar;