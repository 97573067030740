import React from "react";
import {Link} from "react-router-dom";

const Intro = () => {

    return (
        <div>
            <p className={"about"}>
                Moltube.com is a platform on which 3D figures of molecular structures can be edited, shared and
                discussed.
                It's goal is to be simple and intuitive to use for both structural biologists and those who have no
                prior experience with molecular 3D structures.
            </p>   <p className={"about"}>
            You can look at uploaded figures in the <Link to={`/gallery`}>Gallery</Link>, or make your own
            figure in <Link to={`/create`}>Create Figure</Link>. The
            properties of a figure, like color, orientation, depiction, etc. are stored in json-format. For sharing
            a figure privately, you can download a json file of your figure and share it with others via your own
            communication methods. Your friends or colleagues then can load the json file into moltube.com and see the
            figure, but
            the json file does not leave the local browser and your data stays completely private.
        </p>   <p className={"about"}>
            If you want to share your figure with the whole world, you can upload it to the moltube.com server (that
            happens any time you press publish). If the upload was successful, you will see a message with a password.
            This password allows you to delete your figure at any time, so remember to save it somewhere!

        </p>   <p className={"about"}>
            This app is in a very early stage. <Link to={`/feedback`}>Feedback</Link> is very much
            appreciated.

        </p>
        </div>
    );
};

export default Intro;
