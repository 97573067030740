import React from "react";
//import { height, width } from "@material-ui/system";
import {
  CardActionArea,
  Card,
  CardContent,
  CardMedia,
  Button,
  CardActions,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const FigureCard = ({ id, pdb, title, description, sessionid, nickname, date, bgcolor }) => {
    const datum = new Date(date).toDateString()
  return (
      <div className={"figurecard"}>
          <Link to={`/view/${sessionid}`}>
        <img
          alt={
            "3D Figure of a Molecular model from the protein data base with the pdb-id " +
            pdb +
            " title: " +
            title +
            " description: " +
            description
          }
          style={{backgroundColor: bgcolor, "border-radius": "0%"}}
          src={
            `https://moltube-thumbnails.s3.amazonaws.com/${sessionid}.png`
          }
          width="100%"

        /> </Link>
<div style={{'height':'3rem', 'overflow-y':'hidden'}}>
        <b>{title}</b>
</div>
          <div style={{'height':'7rem', 'overflow-y':'hidden', 'margin':'0.2em'}}>
              <p>{description}</p>
          </div>
          <div style={{'display': 'flex', 'justify-content':'space-between'}}>
              <i>{nickname}, {datum}</i>
              <b>{pdb}</b>
                  <Link to={`/view/${sessionid}`}>Open</Link>
          </div>


</div>
  );
};

export default FigureCard;
