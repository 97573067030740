import Stage from "../components/Stage";
import React, {useEffect, useState, useContext} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Description from "../modules/show-figure-ui/Description";
import Comments from "../modules/show-figure-ui/Comments";
import {Grid} from "@material-ui/core";
import {NglContext} from "../modules/create-figure-ui/NglContext";
import StageControls from "../components/StageControls";
import Split from "react-split";
import "../App.css"
import MakeImage from "../modules/create-figure-ui/chainList.components/MakeImage";
import TweetButton from "../modules/show-figure-ui/TweetButton";
import InfoCon from "../modules/create-figure-ui/chainList.components/InfoCon";

export const ViewFigurePage = (props) => {
        const [data, setData] = useState(undefined);
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState(undefined);
        const [exists, setExists] = useState(false)

        const {
            ngl: {stage},
        } = useContext(NglContext);
        const {onNglSave} = useContext(NglContext);

        const handleStageResize = () => {
            console.log("mouseDown")
            if (stage) {
                stage.handleResize();
            }
        }

        useEffect(() => {
            const fetchPdb = async () => {
                try {
                    const response = await fetch(
                        `https://moltube-figure-data.s3.eu-west-1.amazonaws.com/${props.match.params.figureId}.json`
                    );
                    const resJson = await response.json();
                    setData(resJson);
                    setExists(true)
                } catch (e) {
                    setError(e);
                    console.log("the figure you requested does not exist. Maybe it has been deleted.")
                }
                setIsLoading(false);
            };
            fetchPdb();
        }, [props.match.params.figureId]);


        const deleteFigure = async () => {

                const input = prompt("Please enter the password for this figure", "");


                //CHECK IF CORRECT
                const isCorrect = async () => await fetch(
                    `https://figure3d.herokuapp.com/checkpassword/`,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({sessionid: data.info.sessionId, input: input})
                    }).then(res => res.json()).then(value => {
                    console.log("value : ", value)
                    return value
                })

if (input) {

    isCorrect().then((value) => {
        if (value) {
            console.log("starting deletion")
            //======
            const deleteFunc = async () => {
                await fetch(
                    `https://figure3d.herokuapp.com/deletefigure/${data.info.sessionId}`,
                    {
                        method: "DELETE",
                    }
                ).then((res) => {
                    console.log("reading status")
                    if (res.status === 200) {
                        console.log("Success!")
                        setExists(false)
                    } else {
                        console.log("deleting failed!")
                        setExists(true)
                    }
                })
            }
            deleteFunc()

        } else {
            alert("Wrong password. Try again.")
        }
    }

)
}            ;
            }
        ;

        if (isLoading) {
            return (
                <Grid container justify="center">
                    <CircularProgress/>
                </Grid>
            );
        }

        if (!exists) {
            return (
                <div className={"landingContainer"}>
                    <h1>The figure you requested does not exist. Maybe it has been deleted. Please check the id.</h1>
                    <p>Error message: {error?.message}</p>
                </div>
            )
        }


        const datum = new Date(data.info.date).toDateString()

        return (

            <div className={"landingContainer"}>
                <Split className="wrap"
                       sizes={[50, 50]}
                       direction="vertical"
                       onDragEnd={handleStageResize}
                       maxSize={['80vh', '50vh']}
                >
                    <Stage
                        stageId="showfigure"
                        components={data.components}
                        sessionId={props.match.params.figureId}
                        bgColor={data.stageSettings.bgcolor}
                        orientation={data.stageSettings.camerasetting}
                        onNglSave={onNglSave}
                    />

                    <div style={{"overflow-y": 'scroll'}}>
                        <StageControls bgcolor={data.stageSettings.bgcolor} orientation={data.stageSettings.camerasetting}/>


                        <Description date={datum} title={data.info.title} author={data.info.nickname}
                                     description={data.info.description}
                                     sessionid={props.match.params.figureId}/>
                        <b>Components:</b>
                        {data.components.map((item, index) => {
                            return (
                                <div>
                                    <p>{item.name}</p>  <InfoCon comp={index} source={item.source}/>
                                </div>
                            )
                        })}

                        <button onClick={deleteFigure}>
                            Delete this figure
                        </button>
                        <MakeImage/>
                        <a
                            type="button"
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(data)
                            )}`}
                            download="moltube.json"
                        >
                            {`Download Json`}
                        </a>
                        <TweetButton webaddress={`https://moltube.com/#/view/{${data.info.sessionId}`}/>

                        <hr/>
                        <Comments sessionid={props.match.params.figureId}/>
                    </div>


                </Split>

            </div>

        );
    }
;

