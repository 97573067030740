import React from "react";

const TweetButton = ({ webaddress, datahashtags  }) => {
    return (
        <div>
            <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button"
               data-via={webaddress} data-hashtags={datahashtags} data-dnt="true"
               data-show-count="false">Tweet</a>
            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
        </div>

    )
}
export default TweetButton;
