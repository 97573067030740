import "../../css/inputBox.css";
import React from "react";


const Description = ({date, title, author, description, sessionid}) => {
    const t = title ? title : "No title"
    const a = author ? author : "No author"
    const d = description ? description : "No description"


    return (
        <div>
            <h1>{t}</h1>
            <p>{a}, {date}</p>
            <p>{d}</p>
        </div>


    );
};

export default Description;
