import React, {useState} from "react";
import {Button, Container} from "@material-ui/core";
import "../App.css"
import {Link} from "react-router-dom";

export const FeedbackPage = () => {
    const [feedback, setFeedback] = useState(undefined);
    const [sendStatus, setSendStatus] = useState(false);

    const sendFeedback = async () => {

        async function postFeedback() {
            if ((feedback)&&(feedback.content)) {
                console.log(feedback)
                fetch("https://figure3d.herokuapp.com/feedback", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        author: feedback.author ? feedback.author : "Anonymous",
                        content: feedback.content
                    }),
                }).then((res) => {
                    if (res.status === 200) {
                        setSendStatus(true);
                    }
                });
            } else {
                alert("You need to fill the feedback box to send feedback!")
            }
        }

        postFeedback();

    }

    const newFeedback = () => {
        setSendStatus(!sendStatus)
    }

    if (!sendStatus) {
        return (
            <div className={"scroll"}>
                <Container>
                    <h1>Feedback</h1>
                    <p>(I will get your feedback as email. Your feedback won't be published anywhere.)</p>
                    <h4>Your name</h4>
                    <input
                        label="Name"
                        style={{
                            width: "100%",
                            background: "#282c34",
                            color: "white",
                            resize: "none",
                            height: "3rem",
                            "font-size": "1rem"
                        }}
                        value={undefined}
                        placeholder={"Please enter your name or nickname"}
                        onChange={(event) => {
                            setFeedback({...feedback, author: event.target.value});
                        }}
                    ></input>
                    <h4>Your feedback *</h4>
                    <textarea
                        style={{
                            width: "100%",
                            background: "#282c34",
                            color: "white",
                            resize: "none",
                            "font-size": "1rem"
                        }}
                        placeholder={"Please enter your feedback here"}
                        label="Content"
                        rows="5"
                        onChange={(event) => {
                            setFeedback({...feedback, content: event.target.value});
                        }}
                    ></textarea>
                    <div>
                        <Button onClick={sendFeedback} variant="contained">
                            Send Feedback
                        </Button>
                    </div>
                </Container>
            </div>
        );
    } else {
        return <div>
            <h1 style={{color: "white"}}>Thank you for your feedback!</h1>s
            <Link to={`/feedback`} onClick={newFeedback}><h4>Write more feedback</h4></Link>
                <Link to={`/gallery`}><h4>Go to gallery</h4>
            </Link>
        </div>

    }
};
