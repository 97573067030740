import { createContext, useState } from "react";

const initialState = {
  stage: undefined,
  component: undefined,
  //chainList: undefined,
  comparay: undefined,
  chaindatas: undefined
};

const NglContext = createContext();

const NglContextProvider = ({ children }) => {
  const [ngl, setNgl] = useState(initialState);

  const handleNglSave = (ngl) => {
    setNgl(ngl);
  };

  return (
    <NglContext.Provider value={{ ngl, onNglSave: handleNglSave }}>
      {children}
    </NglContext.Provider>
  );
};

export { NglContext, NglContextProvider };
