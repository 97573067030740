import React from "react";
import { useState, useContext, useEffect } from "react";
import { NglContext } from "../NglContext";
import { Button } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Tooltip} from "@material-ui/core";
import { saveAs } from 'file-saver';

const MakeImage = ({name}) => {

  const {
    ngl: { stage },
  } = useContext(NglContext);

  if (false) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  async function makePNG() {
    const imageBlob = await stage.makeImage({
      factor: 1,
      antialias: false,
      trim: false,
      transparent: true,
    }).then(imageBlob => {return new File([imageBlob], "image.png")}).then(file => {saveAs(file,`moltube-download.png`);})
  }

  if (!stage) {
  return null
  }

  if (stage) {
    return (
        <Tooltip title={"download figure as png"} arrow>
          <button onClick={makePNG}>Download PNG</button>
        </Tooltip>

    );
  }
};

export default MakeImage;
