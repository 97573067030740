//this is a button to change che Depiction of a chain Depiction Controller
//it needs a dropdown functionality to display a list of depiction options like cartoon, licorice, etc.
import React, {useEffect} from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useState, useContext } from "react";
import { NglContext } from "../NglContext";
import autoselection_items from "../../../data/autoselection_items.json"

const SelConAuto = ({ chainName, comp, initautoselection}) => {
  const {
    ngl: { stage, comparray, chaindatas },
  } = useContext(NglContext);

  const chainData=chaindatas[comp]
  const component=comparray[comp]

  const [autoselection, setAutoselection] = useState(initautoselection);

  const updateAutoSelection = (e) => {
    setAutoselection(e.target.value);
    chainData[chainName].autoselection = e.target.value;
    stage.getRepresentationsByName(chainName).dispose();
    component.addRepresentation(chainData[chainName].depiction, {
      sele: chainData[chainName].selection.map(item=>`(:${item} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection}))`).join(" ; "),
      color: chainData[chainName].color,
      opacity: chainData[chainName].opacity,
      visible: chainData[chainName].visible,
      name: chainData[chainName].name,
    });
  };

  useEffect(() => {
    setAutoselection(initautoselection)
  }, [initautoselection]);

  if (!chainData) {
    return null;
  }

  return (
    <Select onChange={updateAutoSelection} value={autoselection} style={{'font-size':'0.8em'}}>
        {autoselection_items.map(item => {
                return (
                    <MenuItem key={item.value} value={item.value}>{item.value}</MenuItem>
                    )})}
    </Select>
  );
};

export default SelConAuto;
