import React, {useContext} from "react";
import {NglContext} from "./NglContext";
import ZoomInIcon from '@mui/icons-material/ZoomIn';import {Tooltip} from "@material-ui/core";

import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const Magnify = () => {
    const {
        ngl: {stage},
    } = useContext(NglContext);

    const zoomin = () => {
        if (stage) {
            stage.viewerControls.zoom(0.1)
            console.log(stage.viewerControls.getOrientation())
        }
    }

    const zoomout = () => {
        if (stage) {
            stage.viewerControls.zoom(-0.1)
            console.log(stage.viewerControls.getOrientation())
        }
    }

    if (stage) {
        return (
            <div>
            <Tooltip title={"Zoom in"}>
                <button
                    onClick={() => {
                        zoomin()
                    }}
                >
                    {< ZoomInIcon/>}
                </button>
            </Tooltip>

        <Tooltip title={"Zoom out"}>
            <button
                onClick={() => {
                    zoomout()
                }}
            >
                {< ZoomOutIcon/>}
            </button>
        </Tooltip>
            </div>
        )
    }

    else {
        return null
    }

}

export default Magnify;
