import StageColor from "../modules/create-figure-ui/StageColor";
import ResetOrientation from "../modules/create-figure-ui/ResetOrientation";
import Center from "../modules/create-figure-ui/Center";
import Magnify from "../modules/create-figure-ui/Magnify";

const StageControls = ({bgcolor,orientation}) => {

        return (
            <div style={{display: "flex", direction: "row", 'justify-content':"flex-end"}}>
                {/*<TweetButton/>*/}
                <StageColor bgcolor={bgcolor}/>
                <ResetOrientation orientation={orientation}/>
                <Center/>
                    <Magnify/>
            </div>
        )

}

export default StageControls;
