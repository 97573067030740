import React, {useContext} from "react";
import {NglContext} from "../NglContext";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const DeleteCon = ({chainName, comp}) => {
    const {
        ngl: {stage, chaindatas, comparray},
    } = useContext(NglContext);

    if (!stage || !comparray || !chaindatas) {
        return null
    }

    const chainData = chaindatas[comp]
    const component = comparray[comp]

    const handleClick = () => {
        if (window.confirm("Delete the item? This action is not reversible. (After pressing ok, refresh the chains to see the deletion.")) {
            component.removeAnnotation(chainData[chainName].anno)
            stage.getRepresentationsByName(chainName).dispose();
            if (Object.keys(chainData).length===1) {
                if (window.confirm("Delete the item? This is the last remaining chain. if you proceed, the whole component will be deleted.")) {
                    component.dispose()
                }
            }
            delete chainData[chainName]


        }
    }

    return (
        <div>
            <DeleteForeverIcon onClick={handleClick}/>
        </div>
    );
};

export default DeleteCon;
