import React, {useContext} from "react";
import {NglContext} from "./NglContext";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import {Tooltip} from "@material-ui/core";

const Center = () => {
    const {
        ngl: {stage},
    } = useContext(NglContext);

    const center = () => {
        if (stage) {
            stage.autoView()
        }

    }

    if (stage) {
        return (
            <Tooltip title={"center"}>
                <button
                    onClick={() => {
                        center()
                    }}
                >
                    {< CenterFocusStrongIcon/>}
                </button>
            </Tooltip>

        )
    }

    else {
        return null
    }

}

export default Center;
