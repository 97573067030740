import React, {useState, useContext, useEffect} from "react";
import {NglContext} from "../NglContext";
import InfoIcon from "@mui/icons-material/Info";
import {Button, Drawer, Grid} from "@material-ui/core";


const InfoCon = ({comp, source}) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(undefined)
    const [abstract, setAbstract] = useState(undefined)
    const [pid, setPID] = useState(undefined)

    const {
        ngl: {comparray},
    } = useContext(NglContext);

    const saveClose = () => {
        setOpen(!open);
    };

    const handleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (comparray) {
            const pid = ((source == "pdb")&&(comparray[comp].type != "volume")&&(comparray[comp].structure.extraData.cif)) ? comparray[comp].structure.extraData.cif.citation.pdbx_database_id_PubMed : undefined
            setPID(pid)
        }
    }, [comparray]);

    function getInfo() {
        const component = (comparray[comp].type === "volume") ? undefined : comparray[comp]
        const extraData = ((source == "pdb")&&(comparray[comp].type != "volume")) ? component.structure.extraData : undefined
        return ((source == "pdb")&&(comparray[comp].type != "volume")) ? {
                "title": component.structure.title.replace(/'/gi, ""),
                "authors": extraData.cif.audit_author.name.join(", ").replace(/'/gi, ""),
                "citation": `${extraData.cif.citation.title} ${extraData.cif['citation_author'].name}, ${extraData.cif.citation.journal_abbrev}, volume ${extraData.cif.citation.journal_volume} pages ${extraData.cif.citation.page_first}-${extraData.cif.citation.page_last}, ${extraData.cif.citation.year}`.replace(/'/gi, " "),
                "keywords": extraData.cif.struct_keywords.pdbx_keywords.replace(/'/gi, ""),
                "tags": extraData.cif.struct_keywords.text.replace(/'/gi, ""),
                "abstract": abstract,
                "pid": extraData.cif.citation.pdbx_database_id_PubMed
            } :
            {
                "title": "undefined",
                "authors": "undefined",
                "citation": "undefined",
                "citauthors": "undefined",
                "keywords": "undefined",
                "tags": "undefined",
            }
    }
    useEffect(() => {

        const fetchFunc = async () => {
            const response = await fetch(
                `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id=${getInfo().pid}&rettype=abstract&retmode=XML`
            );
            const resText = await response.text();
            let parser = new DOMParser(),
                xmlDoc = parser.parseFromString(resText, 'text/xml')
            if ( xmlDoc.getElementsByTagName('AbstractText') && xmlDoc.getElementsByTagName('AbstractText')[0]){
                setAbstract(() => xmlDoc.getElementsByTagName('AbstractText')[0].textContent)
            } else {setAbstract(() => "not present")}

        };
        if ((comparray) && (source == "pdb") && (pid)) {
            fetchFunc()
        }

    }, [pid]);

    if ((source != "pdb") || (!abstract) || (!comparray) || (comparray[comp].type === "volume")) {
        return null;

    } else if (source == "pdb")   {
        const info = getInfo()

        return (
            <div>
                <button onClick={handleDrawer}>
                    {<InfoIcon/>}
                </button>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    variant="persistent"
                >
                    <Button onClick={saveClose} variant="contained">
                        Close
                    </Button>
                    <div style={{'width': '95vw'}}>
                        <h1>{info.title}</h1>
                        <p><b>Authors:</b> {info.authors}</p>
                        <p><b>Citation:</b> {info.citation}</p>
                        <p><b>Main Keyword:</b> {info.keywords}</p>
                        <p><b>Tags:</b> {info.tags}</p>
                        <p><b>Abstract:</b> {info.abstract}</p>
                    </div>
                    <Button onClick={saveClose} variant="contained">
                        Close
                    </Button>
                </Drawer>
            </div>
        );
    }


};

export default InfoCon;