import React from "react";
import "../../../css/chainList.css";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {NglContext} from "../NglContext";
import {useState, useContext} from "react";
import {Tooltip} from "@material-ui/core";

const CopyConALL = ({selection, comp}) => {
    const {
        ngl: {comparray, chaindatas},
    } = useContext(NglContext);
    const chainData = chaindatas[comp]
    const component = comparray[comp]

    const handleChange = () => {

        const name = `GROUP (${selection.map(chainName => `${chainData[chainName].id}`).join(", ")})`

        chainData[name] = {
            id: name,
            name: name,
            visible: true,
            color: "#3683ff",
            depiction: "Ribbon",
            description: name,
            selection: selection.map(chainName => chainData[chainName].id),
            autoselection: "all",
            opacity: 1,
            manualselection: "notworking",
        };

        console.log(chainData[name]["manualselection"])

        component.addRepresentation("Ribbon", {
            sele: selection.map(chainName => `(:${chainData[chainName].id} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection}))`).join(" ; "),
            color: "#3683ff",
            opacity: 1,
            name: name,
        });

    };

    if (!component) {
        return null;
    }


    return (
        <Tooltip title={"group and make copy of selection"}>
            <div>
                <FileCopyIcon onClick={handleChange}/>
            </div>
        </Tooltip>
    )
};

export default CopyConALL;
