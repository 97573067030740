import React, {useContext, useState, useEffect} from "react";
import {NglContext} from "../modules/create-figure-ui/NglContext";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core/";
import InputBox from "../modules/create-figure-ui/InputBox";
import Stage from "../components/Stage";
import ChainList from "../modules/create-figure-ui/ChainList";
import MakeImage from "../modules/create-figure-ui/chainList.components/MakeImage";
import Split from "react-split";
import "../App.css"
import StageControls from "../components/StageControls";
import GetComponents from "../modules/create-figure-ui/GetComponents";
import Volume from "../modules/create-figure-ui/Volume"

export const CreateFigurePage = (props) => {
    const [sessionId, setsessionId] = useState(Date.now);
    const [value, setValue] = useState("");
    const [data, setData] = useState(undefined);


    useEffect(() => {
        if (data) {
            setsessionId({getSessionId})
            console.log(data)
        }
    }, [data]);

    useEffect(() => {
    }, [sessionId]);

    const {onNglSave} = useContext(NglContext);

    const {
        ngl: {stage},
    } = useContext(NglContext);


    const handleStageResize = () => {
        console.log("mouseDown")
        if (stage) {
            stage.handleResize();
        }
    }
    const handleChange = (data) => {
        setData(data)
    }

    function getBgColor(data) {
        return data ? data.stageSettings.bgcolor : "#ffffff"
    }

    function getSessionId(data) {
        return data ? data.info.sessionId : "placeholderScene"
    }

    function getComponents(data) {
        return data ? data.components : [{
            name: "3ZP8.cif",
            id: "//files.rcsb.org/download/3ZP8.cif",
            //id:"https://moltube-structures.s3.eu-west-1.amazonaws.com/1efg.pdb",
            chainData: undefined,
            source: "pdb"
        }]
    }

    function getInfo(data) {
        return data ? data.info : undefined
    }

    function getOrientation(data) {
        return data ? data.stageSettings.camerasetting : undefined
    }

    function getInitOrientation(data) {
        if (stage) {
            return data ? data.stageSettings.camerasetting : stage.viewerControls.getOrientation()
        }
    }

    return (
        <div className={"noscroll"}>
            <Grid container direction="row" justify="center" align="center" spacing={0}>
                <GetComponents value={value} onChange={(value) => handleChange(value)}/>
                <InputBox components={getComponents(data)} initInfo={getInfo(data)}/>
                <MakeImage name={getComponents().map(item => item.name).join("-")}/>
            </Grid>

            <Split className="wrap"
                   sizes={[50, 50]}
                   direction="vertical"
                   onDragEnd={handleStageResize}
                   maxSize={['80vh', '50vh']}
            >
                <Stage
                    stageId="viewport"
                    onNglSave={onNglSave}
                    sessionId={sessionId}
                    components={getComponents(data)}
                    bgColor={getBgColor(data)}
                    orientation={getOrientation(data)}
                />



                <div className={"scroll"}>
                    <StageControls bgcolor={getBgColor(data)} orientation={getInitOrientation(data)}/>
                    {getComponents(data).map((component, index) => {

                        return (((component.source === "emdb") || (component.id.slice(-4,) === "map") || (component.id.slice(-4,) === "mrc") || (component.id.slice(-4,) === "ccp4")) ?
                               <div> <Volume comp={index} name={component.name} source={component.source}/>
                            <br></br>   </div>
                                :<div>
                                <ChainList key={component.name} comp={index} id={component.name}
                                           source={component.source}/> <br></br></div>
                        )
                    })}
                </div>

            </Split>

        </div>

    );
};


