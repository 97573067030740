import React, {useState, useContext, useEffect} from "react";
import {NglContext} from "../NglContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const VisCon = ({chainName, comp, initvisible}) => {
    const {
        ngl: {stage, chaindatas},
    } = useContext(NglContext);

    const chainData = chaindatas[comp]
    const [bool, setBool] = useState(initvisible == "true" ? true : false);


    const handleChange = () => {
        bool ? hide() : show()
    }

    const show = () => {
        setBool(true);
        stage.getRepresentationsByName(chainName).setVisibility(true);
        chainData[chainName].visible = "true";

    };

    const hide = () => {
        setBool(false);
        stage.getRepresentationsByName(chainName).setVisibility(false);
        chainData[chainName].visible = "false";
    };

    useEffect(() => {
      setBool(initvisible)
    }, [initvisible]);

    if (!chainData) {
        return null;
    }

    return (
        <button
            onClick={
                handleChange
            }
        >
            {bool ? <VisibilityOffIcon/> : <VisibilityIcon/>}
        </button>
    );
};

export default VisCon;
