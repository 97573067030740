//this is a button to change che Depiction of a chain Depiction Controller
//it needs a dropdown functionality to display a list of depiction options like cartoon, licorice, etc.
import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useState, useContext } from "react";
import { NglContext } from "../NglContext";
import { Tooltip } from "@material-ui/core";
import depiction_items from "../../../data/depiction_items.json";

const DepConALL = ({ selection, initdepiction, comp }) => {
  const {
    ngl: { stage, comparray, chaindatas },
  } = useContext(NglContext);

  const component=comparray[comp]
    const chainData=chaindatas[comp]


  const [depiction, setDepiction] = useState(initdepiction);

  const updateDepiction = (e) => {
      selection.forEach(
          chainName => {
              setDepiction(e.target.value);
              stage.getRepresentationsByName(chainName).dispose();
              component.addRepresentation(e.target.value, {
                  sele: chainData[chainName].selection.map(item=>`(:${item} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection}))`).join(" ; "),
                  color: chainData[chainName].color,
                  opacity: chainData[chainName].opacity,
                  visible: chainData[chainName].visible,
                  name: chainData[chainName].name,
              });
              chainData[chainName].depiction = e.target.value;
          })
  };
  return (
      <Tooltip title={"apply depiction to selection in bulk"}>
    <Select onChange={updateDepiction} value={depiction} style={{'font-size':'0.8em'}}>


        {depiction_items.map(item => {
            return (
                <MenuItem value={item.value}>{item.value}</MenuItem>
            )})}


    </Select>
      </Tooltip>
  );
};

export default DepConALL;
