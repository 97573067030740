import React from "react";

const UploadStructure = (props) => {
    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        const name=e.target.files[0].name
        fileReader.onload = e => {
            props.onChange({id:e.target.result, name:name})
        };
    };
    return (
        <>
            <input type="file" accept=".pdb,.cif,.map,.mrc,.ccp4" onChange={handleChange} value={props.value} />
        </>
    );
}

export default UploadStructure;
