import React, {useState} from "react";
import {Button, Container, TextField} from "@material-ui/core";

export const LoginPage = () => {
    const [loginData, setLoginData] = useState({});
    const [registerData, setRegisterData] = useState({});
    const [sendStatus, setSendStatus] = useState(false);

    const sendLoginData = async () => {
        console.log("posting data...");
        async function postLoginData() {
            const res = fetch("", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: loginData.email,
                    password: loginData.password,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    setSendStatus(true);
                }
            });
        }
        postLoginData();
    };

    const sendRegisterData = async () => {
        console.log("posting data...");
        async function postRegisterData() {
            const res = fetch("", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: loginData.email,
                    password: loginData.password,
                }),
            }).then((res) => {
                if (res.status === 200) {
                    setSendStatus(true);
                }
            });
        }
        postRegisterData();
    };

    if (!sendStatus) {
        return (
            <div>
                <Container>
                    <h1>Login</h1>
                    <TextField
                        label="Email"
                        variant="filled"
                        style={{ width: "80%" }}
                        onChange={(event) => {
                            setLoginData({ ...loginData, email: event.target.value });
                        }}
                    ></TextField>
                    <TextField
                        label="Password"
                        variant="filled"
                        onChange={(event) => {
                            setLoginData({ ...loginData, password: event.target.value });
                        }}
                        style={{ width: "80%" }}
                    ></TextField>
                    <div>
                        <Button onClick={sendLoginData} variant="contained">
                            Login
                        </Button>
                    </div>
                </Container>
                <Container>
                    <h1>Register</h1>
                    <TextField
                        label="Email"
                        variant="filled"
                        style={{ width: "80%" }}
                        onChange={(event) => {
                            setRegisterData({ ...registerData, email: event.target.value });
                        }}
                    ></TextField>
                    <TextField
                        label="Password"
                        variant="filled"
                        onChange={(event) => {
                            setRegisterData({ ...registerData, password: event.target.value });
                        }}
                        style={{ width: "80%" }}
                    ></TextField>
                    <div>
                        <Button onClick={sendRegisterData} variant="contained">
                            Register
                        </Button>
                    </div>
                </Container>
            </div>
        );
    } else {
        return <h1>Thank you for your feedback!</h1>;
    }
};
