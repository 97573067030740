//https://github.com/dharmelolar/material-UI-navbar/blob/master/src/component/Navbar.js

import React, { useState } from "react";
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import menu_items from "../data/menu_items.json";

const useStyles = makeStyles(()=>({
    link:{
        textDecoration:"none",
        color: "black",
        fontSize: "20px",
    },
    icon:{
        color: "white"
    }
}));

function DrawerComponent() {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <List>

                    {menu_items.map((item) => {
                        return (
                            <ListItem onClick={() => setOpenDrawer(false)}>
                                <ListItemText>
                                    <Link to={item.link} className={classes.link}>{item.name}</Link>
                                </ListItemText>
                            </ListItem>

                        )})}

                </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
                <MenuIcon />
            </IconButton>
        </>
    );
}
export default DrawerComponent;