import "./App.css";

import "./css/wer.css";
import { Switch, HashRouter, Route } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage";
import { GalleryPage } from "./pages/GalleryPage";
import { ViewFigurePage } from "./pages/ViewFigurePage";
import { CreateFigurePage } from "./pages/CreateFigurePage";
import { LoginPage } from "./pages/LoginPage";
import { FeedbackPage } from "./pages/FeedbackPage";
import { NglContextProvider } from "./modules/create-figure-ui/NglContext";
import Navbar from "./components/Navbar";

document.body.style.backgroundColor = "#282c34";
document.body.style.overflowY = "hidden"
document.body.style.overflowX = "hidden"


const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      {children}
    </div>
  );
};

function App() {
  return (
    <HashRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route
            exact path="/create"
            render={(props) => (
              <NglContextProvider>
                <CreateFigurePage {...props} />
              </NglContextProvider>
            )}
          />

            <Route
                exact path="/view/:figureId"
                render={(props) => (
                    <NglContextProvider>
                        <ViewFigurePage {...props} />
                    </NglContextProvider>
                )}
            />


          <Route exact path="/landing" component={LandingPage} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/feedback" component={FeedbackPage} />
            <Route exact path="/login" component={LoginPage} />
        </Switch>
      </Layout>
    </HashRouter>
  );
}

export default App;

// TODO:
// Apply/restrict WebGL reactions to letters "i" and "k" in gallery.
