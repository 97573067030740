//this is a button to change the color of a chain: Color Controller
//it needs to be associated with the following function:

import React, {useState, useContext, useEffect} from "react";

import { NglContext } from "../NglContext";
import { Tooltip } from "@material-ui/core";

const ColConALL = ({ selection, initcolor, comp  }) => {
  const {
    ngl: { stage, chaindatas },
  } = useContext(NglContext);

    const chainData=chaindatas[comp]

  const [color, setColor] = useState(initcolor);

  const handleChange = (value) => {
      setColor(value)
    selection.forEach(
        chainName => {
          stage.getRepresentationsByName(chainName).setColor(value);
          chainData[chainName].color = value;
        })
  };

  if (stage) {
  }
  return (
    <div>
        <Tooltip title={"apply color to selection in bulk"}>
            <input
                type="color"
                id="colorpicker"
                value={color}
                onChange={(event) => handleChange(event.target.value)}
            ></input>
        </Tooltip>

    </div>
  );
};

export default ColConALL;
