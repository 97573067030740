//this is a button to change che Depiction of a chain Depiction Controller
//it needs a dropdown functionality to display a list of depiction options like cartoon, licorice, etc.
import React, {useEffect} from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useState, useContext } from "react";
import { NglContext } from "../NglContext";
import depiction_items from "../../../data/depiction_items.json"

const DepCon = ({ chainName, initdepiction, comp }) => {
  const {
    ngl: { stage, comparray, chaindatas },
  } = useContext(NglContext);

  const chainData=chaindatas[comp]
  const component=comparray[comp]

  const [depiction, setDepiction] = useState(initdepiction);


  const updateDepiction = (e) => {
    setDepiction(e.target.value);
    stage.getRepresentationsByName(chainName).dispose();
    const sele = chainData[chainName].selection.map(item => `(:${item} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection}))`).join(" ; ")
      component.addRepresentation(e.target.value, {
      sele: chainData[chainName].selection.map(item => `(:${item} and ${chainData[chainName].autoselection} and (${chainData[chainName].manualselection}))`).join(" ; "),
      color: chainData[chainName].color,
      opacity: chainData[chainName].opacity,
      name: chainData[chainName].name,
    });
    chainData[chainName].depiction = e.target.value;
    console.log(sele, e.target.value)
  };

  useEffect(() => {
      setDepiction(initdepiction)
  }, [initdepiction]);

  if (!chainData) {
    return null;
  }

  return (
    <Select onChange={updateDepiction} value={depiction} style={{'font-size':'0.8em'}}>
        {depiction_items.map(item => {
                return (
                    <MenuItem key={item.value} value={item.value}>{item.value}</MenuItem>
                    )})}
    </Select>
  );
};

export default DepCon;
