import React, {useState, useContext, useEffect} from "react";
import Slider from "@material-ui/core/Slider";
import { NglContext } from "../NglContext";
import { makeStyles } from "@material-ui/core/styles";

const TransCon = ({ chainName, comp, initopacity }) => {
  const {
    ngl: { stage, chaindatas },
  } = useContext(NglContext);
  const chainData=chaindatas[comp]

  const useStyles = makeStyles({
    root: {
      width: "10vw",
    },
  });
  const classes = useStyles();

  const [transparency, setTransparency] = useState(parseFloat(initopacity)*100);

  useEffect(() => {
      setTransparency(parseFloat(initopacity)*100)
  }, [initopacity]);

  const handleChange = (value) => {
    value = value / 100;
    stage.getRepresentationsByName(chainName).setParameters({ opacity: value });
    chainData[chainName].opacity = value
  };

  if (!chainData) {
    return null;
  }

else {
    return (
        <div className={classes.root}>
          <Slider
              key={transparency}
              defaultValue={transparency}
              onChange={(e, val) => handleChange(val)}
              max={100}
              min={0}
          />
        </div>
    );
  }

};

export default TransCon;