//this is one row in the interactive listing of chains of a pdb-structure
import React, {useContext} from "react";
import AddCon from "./AddCon";
import ColCon from "./ColCon";
import DepCon from "./DepCon";
import ChainCon from "./ChainCon";
import SelCon from "./SelCon";
import VisCon from "./VisCon";
import LabelMaker from "./LabelMaker";
import CopyCon from "./CopyCon";
import TransCon from "./TransCon";
import DeleteCon from "./DeleteCon"
import Grid from "@material-ui/core/Grid";
import {Tooltip} from "@material-ui/core";
import CenterChain from "./CenterChain"
import SelConManual from "./SelConManual";
import SelConAuto from "./SelConAuto";
import "../../../App.css"


import {
    addCon,
    chainCon,
    visCon,
    colCon,
    depCon,
    selCon,
    transCon,
    copyCon,
    labelCon,
    deleteCon,
} from "../../../data/chainListRowHoverText.json";
import {NglContext} from "../NglContext";

//chainProps is the collection of attributes with values of a single chain.

const Row = ({pdb, name, id, visible, description, depiction, color, comp, opacity, autoselection, labelText, manualselection}) => {

    const {
        ngl: { comparray},
    } = useContext(NglContext);

    const component=comparray[comp]
    const arr1 = []
    component.structure.eachResidue((rp)=>arr1.push([rp.resno, rp.resname, rp.chainname]))
    const arr = arr1.filter(residues => residues[2] == name.split("_")[1])


    const items = [
        // { jsx: <AddCon chainId={id} />, text: addCon },
        {jsx: <DeleteCon comp={comp} chainName={name}/>, text: deleteCon},
        {jsx: <CenterChain chainId={id} chainName={name} comp={comp}/>, text: "zoom"},
        {
            jsx: <ChainCon chainId={id} chainName={name} description={description}/>,
            text: chainCon,
        },
        {jsx: <VisCon chainId={id} chainName={name} initvisible={visible} comp={comp}/>, text: visCon},
        {jsx: <TransCon chainId={id} chainName={name} comp={comp} initopacity={opacity}/>, text: transCon},
        {jsx: <ColCon chainId={id} chainName={name} initcolor={color} comp={comp}/>, text: colCon},
        {jsx: <DepCon chainId={id} chainName={name} initdepiction={depiction} comp={comp}/>, text: depCon},
        {jsx: <SelConAuto chainId={id} chainName={name} initautoselection={autoselection} comp={comp}/>, text: visCon},
        // { jsx: <SelCon chainId={id} chainName={name} />, text: selCon },
        {jsx: <SelConManual chainId={id} chainName={name} initautoselection={autoselection} initmanualselection={manualselection} comp={comp} pdb={pdb} arr={arr}/>, text: visCon},
        {jsx: <LabelMaker chainId={id} chainName={name} comp={comp} initLabelText = {labelText}/>, text: labelCon},
        {jsx: <CopyCon chainId={id} chainName={name} comp={comp}/>, text: copyCon},


    ];

    return (
        <div>
            <div className="row">
                <Grid
                    container
                    background="pink"
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {items.map(({jsx, text}) => {
                        return (
                            <Tooltip title={text} arrow>
                                <div>{jsx}</div>
                            </Tooltip>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
};

export default Row;
