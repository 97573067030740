import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import React, {useContext, useState} from "react";
import {NglContext} from "./NglContext";
import { Tooltip } from "@material-ui/core";

const ResetOrientation = ({orientation}) => {
    const {
        ngl: {stage},
    } = useContext(NglContext);

    const resetOrientation = () => {
        stage.viewerControls.orient(orientation.elements)
    }

    if (stage) {


        return (
            <Tooltip title={"Reset orientation"} arrow>
                <button
                    onClick={() => {
                        resetOrientation()
                    }}
                >
                    {< SettingsBackupRestoreIcon/>}
                </button>
            </Tooltip>
        );

    }

    else {
        return null
    }

}

export default ResetOrientation;
