// this is a List of all chains in a molecule where for each chain a Row.js is generated.
// this has to be given an object with all the chains.
import React, {useState, useEffect, useContext} from "react";
import Row from "./chainList.components/Row";
import "../../css/chainList.css";
import {NglContext} from "./NglContext";
import Grid from "@material-ui/core/Grid";
import ChainListMenu from "./ChainListMenu";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import VisConALL from "./chainList.components/VisConALL";
import TransConALL from "./chainList.components/TransConALL";
import ColConALL from "./chainList.components/ColConALL";
import DepConALL from "./chainList.components/DepConALL";
import CopyConALL from "./chainList.components/CopyConALL";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Tooltip} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelConAutoALL from "./chainList.components/SelConAutoALL";
import InfoCon from "./chainList.components/InfoCon";

const ChainList = ({comp, id, source}) => {
    const [searchChain, setSearchChain] = useState("");
    const [transformedChainData, setTransformedChainData] = useState(undefined);
    const [filteredArray, setfilteredArray] = useState(undefined);
    const [selection, setselection] = useState([])
    const [expand, setExpand] = useState(true);
    const toggleAcordion = () => {
        setExpand((prev) => !prev);
    }

    const {
        ngl: {chaindatas},
    } = useContext(NglContext);

    useEffect(() => {
        setTransformedChainData(undefined)
        if (chaindatas) {
            if (chaindatas[comp]) {
                const transformedChainData = Object.entries(chaindatas[comp]).map(
                    ([chainId, chainProps]) => [chainId, chainProps]
                );
                setTransformedChainData(transformedChainData);
                const filteredArray = transformedChainData.filter(item => item[1].description.toLowerCase().includes(searchChain.toLowerCase()))
                setfilteredArray(filteredArray)
                setselection(filteredArray.map(chain => chain[0]))
            }
        }
    }, [chaindatas]);

    const refreshSelection = () => {
        if (chaindatas) {
            if (chaindatas[comp]) {
                const transformedChainData = Object.entries(chaindatas[comp]).map(
                    ([chainId, chainProps]) => [chainId, chainProps]
                );
                setTransformedChainData(transformedChainData);
                const filteredArray = transformedChainData.filter(item => item[1].description.toLowerCase().includes(searchChain.toLowerCase()))
                setfilteredArray(filteredArray)
                setselection(filteredArray.map(chain => chain[0]))
            }
        }
    }

    useEffect(() => {
        if (transformedChainData) {
            const filteredArray = transformedChainData.filter(item => item[1].description.toLowerCase().includes(searchChain.toLowerCase()))
            setfilteredArray(filteredArray)
            setselection(filteredArray.map(chain => chain[0]))
        }
    }, [searchChain])

    if (!transformedChainData) {
        return <h1>{`Loading component ${comp + 1}. . .`}</h1>;
    }

    return (

        <Grid
            container
            background="pink"
            direction="column"
            alignItems="center"
            spacing={0}
        >
            <div class={"chainListBox"}>


                <Accordion expanded={expand}>

                    <AccordionSummary
                        expandIcon={<Tooltip title={"Expand"}>
                            <ExpandMoreIcon onClick={toggleAcordion}/>
                        </Tooltip>}
                        aria-controls="panel1a-content"
                    >
                        {/*<TitleCon comp={comp}/>*/}


                        <div style={{display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center", overflowY: "scroll"}}>

                            <div style={{
                                display: "flex",
                                direction: "row",
                                width: "90vw",
                                justifyContent: "center",
                                overflowY: "scroll",
                            }}>

                                <b>{id}</b>

                                <Tooltip title={"Get info on Component"}>
                                    <InfoCon source={source} comp={comp}/>
                                </Tooltip>

                                <input
                                    placeholder="filter chains"
                                    type="search"
                                    value={searchChain}
                                    onChange={(event) => {
                                        setSearchChain(event.target.value);
                                    }}
                                />

                                <Tooltip title={"Update chainList for new chains"}>
                                    <button
                                        onClick={() => {
                                            refreshSelection()
                                        }}
                                    >
                                        {< RefreshIcon/>}
                                    </button>
                                </Tooltip>

                            </div>
                            <div style={{
                                display: "flex",
                                direction: "row",
                                width: "90vw",
                                justifyContent: "center",
                                overflowY: "scroll"
                            }}>

                                <Tooltip title={"use your filtered selection to apply properties in bulk"}>
                                    <ArrowForwardIcon/>
                                </Tooltip>
                                <VisConALL selection={selection} comp={comp}/>
                                <TransConALL selection={selection} comp={comp} initopacity={1}/>
                                <ColConALL selection={selection} initcolor="#ffffff" comp={comp}/>
                                <DepConALL selection={selection} initdepiction="Cartoon" comp={comp}/>
                                <SelConAutoALL selection={selection} comp={comp} initautoselection="all"/>
                                <CopyConALL selection={selection} comp={comp}/>
                            </div>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>


                        <div className="chainList">
                            <div>
                                <hr/>
                            </div>
                            {filteredArray.map(([chainName, chainProps]) => {
                                    return (
                                        <div key={chainName}>
                                            <Row {...chainProps} comp={comp} pdb={id}/>
                                            <hr></hr>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Grid>
    )
        ;
};

export default ChainList;
