import React, {useState, useEffect} from "react";
import InfoIcon from "@mui/icons-material/Info";
import {Button, Drawer} from "@material-ui/core";


const InfoConVol = ({name}) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(undefined)
    const [abstract, setAbstract] = useState(undefined)
    const [pid, setPID] = useState(undefined)

    const saveClose = () => {
        setOpen(!open);
    };

    const handleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {

        const fetchFunc = async () => {
            const response = await fetch(
                `https://www.ebi.ac.uk/emdb/api/entry/${name}`);
            const resJson = await response.json();
            setData(resJson)
            const citype = resJson.crossreferences.citation_list.primary_citation.citation_type
            setPID(citype.published ? citype.external_references.filter(item => item.type_ === "PUBMED")[0].valueOf_ : false)

        };
        fetchFunc()

    }, [name]);

    function getInfo() {
        const citype = data ? data.crossreferences.citation_list.primary_citation.citation_type : undefined
        return data ? {
                "title": data.admin.title,
                "authors": data.admin.authors_list.author.join(", "),
                "citation": citype.published ? "true" : "Not published",
                "abstract": abstract,
                "pid":  citype.published ? citype.external_references.filter(item => item.type_ === "PUBMED")[0].valueOf_ : "Not published"
            } :
            {
                "title": "undefined",
                "authors": "undefined",
                "citation": "undefined",
                "citauthors": "undefined",
                "keywords": "undefined",
                "tags": "undefined",
            }
    }

    useEffect(() => {

        const fetchFunc = async () => {
            if (pid) {
                const response = await fetch(
                    `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id=${getInfo().pid}&rettype=abstract&retmode=XML`
                );
                const resText = await response.text();
                let parser = new DOMParser(),
                    xmlDoc = parser.parseFromString(resText, 'text/xml')
                setAbstract((abstract) => xmlDoc.getElementsByTagName('AbstractText')[0].textContent)
            }

        };
        fetchFunc()

    }, [pid]);

    if (!data) {
        return null;

    } else {
        const info = getInfo()

        return (
            <div>
                <button onClick={handleDrawer}>
                    {<InfoIcon/>}
                </button>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    variant="persistent"
                >
                    <Button onClick={saveClose} variant="contained">
                        Close
                    </Button>
                    <div style={{'width': '100vw'}}>
                        <h1>{info.title}</h1>
                        <p><b>Authors:</b> {info.authors}</p>
                        <p><b>Citation:</b> {info.citation}</p>
                        <p><b>Abstract:</b> {info.abstract}</p>
                        <p><b>PID:</b> {info.pid}</p>
                    </div>
                </Drawer>
            </div>
        );
    }
    ;
}
export default InfoConVol;