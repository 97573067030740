import React, {useEffect} from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useState, useContext } from "react";
import { NglContext } from "../NglContext";


const SelConManual = ({ pdb, chainName, comp, arr, initmanualselection}) => {
  const {
    ngl: { stage, comparray, chaindatas },
  } = useContext(NglContext);

  const chainData=chaindatas[comp]
  const component=comparray[comp]

  const [manualselection, setManualselection] = useState(undefined);

    const [start, setStart] = useState((initmanualselection)  ? initmanualselection.split("-")[0] :  arr[0][0])
    const [end, setEnd] = useState((initmanualselection) ? initmanualselection.split("-")[1] :  arr[arr.length-1][0])
    console.log(start)
    useEffect(() => {
    setManualselection(initmanualselection)
          setStart( initmanualselection.split("-")[0])
          setEnd( initmanualselection.split("-")[1])
        console.log("pdb",pdb)
  }, [ initmanualselection]);


  const addStart = (e) => {
      setStart(e.target.value)
          const mansel = `${e.target.value}-${end}`
          setManualselection(mansel)
          chainData[chainName].manualselection = mansel;
          stage.getRepresentationsByName(chainName).dispose();
          component.addRepresentation(chainData[chainName].depiction, {
              sele: chainData[chainName].selection.map(item=>`(:${item} and ${chainData[chainName].autoselection} and (${mansel}))`).join(" ; "),
              color: chainData[chainName].color,
              opacity: chainData[chainName].opacity,
              visible: chainData[chainName].visible,
              name: chainData[chainName].name,
          });

      };

    const addEnd = (e) => {
        setEnd(e.target.value)
        const mansel = `${start}-${e.target.value}`
        setManualselection(mansel)
        chainData[chainName].manualselection = mansel;
        stage.getRepresentationsByName(chainName).dispose();
        component.addRepresentation(chainData[chainName].depiction, {
            sele: chainData[chainName].selection.map(item=>`(:${item} and ${chainData[chainName].autoselection} and (${mansel}))`).join(" ; "),
            color: chainData[chainName].color,
            opacity: chainData[chainName].opacity,
            visible: chainData[chainName].visible,
            name: chainData[chainName].name,
        });

    };


  return (
      <div style={{'display':'flex','flex-direction':'row','border':'solid grey'}} key={component.parameters.name}>
    <div>

        <Select onChange={addStart} value={start} style={{'font-size':'0.8em'}}>
            {
                arr.filter(item => item[0] <= end).map(item => {
                return (
                    <MenuItem key={`${item[0]} ${item[1]}`} value={item[0]}>{`${item[0]} ${item[1]}`}</MenuItem>
                )})}
        </Select>

        <Select onChange={addEnd} value={end} style={{'font-size':'0.8em'}}  >
            {arr.filter(item => item[0] >= start).map(item => {
                return (
                    <MenuItem key={item[0]} value={item[0]}>{`${item[0]} ${item[1]}`}</MenuItem>
                )})}
        </Select>


      </div>
      </div>
);
};

export default SelConManual;
