import React, {useState, useContext} from "react";
import {NglContext} from "../NglContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Tooltip } from "@material-ui/core";

const VisConALL = ({selection, comp }) => {
    const {
        ngl: { stage, chaindatas},
    } = useContext(NglContext);

    const chainData=chaindatas[comp]

    const [bool, setBool] = useState(true);

    function setVisibility(chainName,boolean) {
        setBool(boolean);
        stage.getRepresentationsByName(chainName).setVisibility(boolean);
        chainData[chainName].visible = boolean;
    }

    const show = () => {
        selection.forEach(
            chainName => setVisibility(chainName, true)
        );
    };

    const hide = () => {
        selection.forEach(
            chainName => setVisibility(chainName, false)
        );
    };

    return (
        <Tooltip title={"show/hide selection in bulk"}>
            <button
                onClick={() => {
                    bool ? hide() : show();
                    setBool(!bool);
                }}
            >
                {bool ? <VisibilityOffIcon/> : <VisibilityIcon/>}
            </button>
        </Tooltip>

    );
};

export default VisConALL;
