import Grid from "@material-ui/core/Grid";
import React, {useState, useEffect} from "react";
import "../App.css";
import references from "../data/references.json";
import Intro from "../modules/landing/Intro";
import {Link} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export const LandingPage = () => {
    const [latest, setLatest] = useState(undefined);
    const [data, setdata] = useState(undefined);

    useEffect(() => {
        const fetchFunc = async () => {
            const response = await fetch(
                "https://moltube-data.s3.eu-west-1.amazonaws.com/news.json"
            );
            const resJson = await response.json();
            setdata((data) => resJson);

        };
        fetchFunc();
    },[]);

    useEffect(() => {
        const fetchFunc = async () => {
            const response = await fetch(
                "https://figure3d.herokuapp.com/latestFigure"
            );
            const resJson = await response.json();
            setLatest((latest) => resJson);

        };
        fetchFunc();

    },[]);

    const displayLatestFigure = () => {
        return (latest) ? (<><Link to={`/view/${latest.sessionid}`}><h4>{latest.title}</h4>
            </Link> <img src={`https://moltube-thumbnails.s3.amazonaws.com/${latest.sessionid}.png`} alt={"Image not found"}/></>
        ) : <CircularProgress/>
    }

    const displayNews = () => {
        return data ? (<div>
            <h2>News</h2>
            <div className={"kachel"}>
                {data.map((item, index) => (
                    <div className={"news"}>
                        <h5>{item.date} - {item.title}</h5>
                        <p>{item.content}</p>
                        <hr/>
                    </div>
                ))}
            </div>

        </div>) : <CircularProgress/>
    }


    return (  <div className={"scroll"}>
        <Grid container spacing={5} justify="space-around" className={"landingContainer"}>

                <Grid item md={11} xs={11} lg={11}>
                    <div className={"about"}>
                        <h2>About</h2>
                        <Intro/>
                        <hr/>
                    </div>

                </Grid>
                <Grid item md={4} xs={12} lg={4} p={2}>

                    <h2>Latest figure</h2>
                    {displayLatestFigure()}
                </Grid>
                <Grid item md={4} xs={12} lg={4}>
                    {displayNews()}
                </Grid>

                <Grid item md={11} xs={11} lg={11} p={2}>
                    <div>
                        <h2>References</h2>
                        {references.map((item) => {
                            return (
                                <ul className={"about"}>
                                    <li key={item.id}>
                                        {item.ref},{" "}
                                        <a
                                            href={item.link}
                                            className="active"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Link
                                        </a>
                                    </li>
                                    <br></br>
                                </ul>
                            );
                        })}

                    </div>
                </Grid>


        </Grid>
        </div>


    );
};
