import React, {useState, useEffect, useContext} from "react";
import {Drawer, Button, Grid, Tooltip} from "@material-ui/core";
import "../../App.css";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {withRouter,} from 'react-router';
import {v4 as uuidv4} from 'uuid';
import UploadJSON from "./UploadJSON";
import UploadStructure from "./UploadStructure";


const GetComponents = (props) => {
    const handleSubmit = () => {
        if (components.length > 0) {
            props.onChange({info: info, stageSettings: stageSettings, components: components})
            setOpen(false)
            console.log("handling submit", components)
        } else {
            handleClear()
        }

    };

    const handleClear = () => {
        setComponents([])
    };


    const [source, setSource] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [components, setComponents] = useState([])
    const [data, setData] = useState(undefined);
    const [data2, setData2] = useState(undefined);
    const [value3, setValue3] = useState("");
    const [value2, setValue2] = useState(undefined);
    const [added, setAdded] = useState(false);
    const [stageSettings, setStageSettings] = useState({
        camerasetting: undefined,
        bgcolor: "#ffffff"
    });
    const [info, setInfo] = useState({sessionId: uuidv4()})

    const handleDrawer = () => {
        setComponents([])
        setInfo({sessionId: uuidv4()})
        setOpen(!open);
    };

    const saveClose = () => {
        setOpen(!open);
    };

    const handleClick = (e) => {
        setSource(e)
        setClicked(!clicked)
    }

    useEffect(() => {
    }, [clicked]);

    useEffect(() => {
    }, [added])

    const sources = ["pdb", "alphafold", "emdb", "moltube", "localjson", "localstructure"]

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleChange = (newValue) => {
        setData(newValue)
    }

    const handleChange2 = (newValue) => {
        console.log(newValue)
        setData2(newValue)
    }

    const displaySelection = () => {
        if (components.length > 0) {
            return (
                <>
                    <h3>Selected Components:</h3>
                    {components.map(item => {
                            return <li>{item.name}</li>
                        }
                    )}

                    <br/>

                    <Button id = "submitall" onClick={handleSubmit}>
                        Submit all
                    </Button>
                    <Button onClick={handleClear}>
                        Clear all
                    </Button>
                </>

            )

        }
    }

    useEffect(() => {
    }, [added]);

    function pushContent(name, id, chainData, source) {
        if (!components.map(item => item.name).includes(name)) {
            components.push({
                name: name,
                id: id,
                chainData: chainData,
                source: source
            })
        }
        fetch(id, {
                method: "HEAD",
                mode: "cors"
            }
        ).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
        }).catch(function() {
            alert(`${id} not found. Please check your input.`)
            components.pop()
        });
    }

    const handleAdd = () => {
        const input = document.getElementById(`${source}input`)

        if ((source === "pdb") && (input.value.length > 0)) {
            const url = `https://files.rcsb.org/download/${input.value}.cif`
            pushContent(input.value, url, undefined, source)
        } else if ((source === "emdb") && (input.value.length > 0)) {
            // const url = `https://ftp.ebi.ac.uk/pub/databases/emdb/structures/EMD-${input.value}/map/emd_${input.value}.map.gz`
            // pushContent(input.value, url, undefined, source)
            if (!components.map(item => item.name).includes(input.value)) {
                components.push({
                    name: input.value,
                    id: `https://ftp.ebi.ac.uk/pub/databases/emdb/structures/EMD-${input.value}/map/emd_${input.value}.map.gz`,
                    chainData: undefined,
                    source: source
                })
            }
        } else if ((source === "alphafold") && (input.value.length > 0)) {
            const url = `https://alphafold.ebi.ac.uk/files/${input.value}.pdb`
            pushContent(input.value, url, undefined, source)
        } else if (source === "localjson") {
            data.components.map(component => pushContent(component.name, component.id, component.chainData, component.source))
            setInfo(data.info)
            setStageSettings(data.stageSettings)
        } else if (source == "localstructure") {
            components.push({name: data2.name, id: data2.id, chainData: undefined, source: source})
        } else if ((source == "moltube") && (input.value.length > 0)) {
            const fetchFunc = async () => {
                const response = await fetch(
                    `https://moltube-figure-data.s3.eu-west-1.amazonaws.com/${input.value}.json`
                );
                const resJson = await response.json();
                resJson.components.map(component => pushContent(component.name, component.id, component.chainData, component.source))
                setInfo(resJson.info)
                setStageSettings(resJson.stageSettings)
            };
            fetchFunc();


        }
    setAdded(!added)
}

const getInputField = () => {
    if (source == "pdb") {
        return (
            <>
                <h3>Enter your pdb id, e.g. <i>1efg</i></h3>
                <input
                    id="pdbinput"
                    placeholder="1efg"
                    type="text"
                    className="smallTextBox"
                    maxLength="4"
                />
                <Button onClick={handleAdd}>
                    Add
                </Button>
            </>)
    } else if (source === "emdb") {
        return (<>
                {/*<p>Sorry, loading files from EMDB stopped working. It will be repaired as soon as possible.</p>*/}
                <h3>Enter your emdb id, e.g. <i>24795</i></h3>
                <h4 style={{'color': 'red'}}>Volumes take a long time to load and need a lot of computation power to
                    be rendered! <br/>Currently supported are "mrc", "map", and "ccp4" files. <br/> Please write a
                    feedback if you see need for other file formats.</h4>
                <input
                    id="emdbinput"
                    placeholder="24795"
                    type="text"
                    className="smallTextBox"
                    maxLength="5"
                />
                <Button onClick={handleAdd}>
                    Add
                </Button>
            </>
        )
    } else if (source == "alphafold") {
        return (
            <>
                <h3>Enter your alphafold id, e.g. <br></br> <i>AF-Q76EI6-F1-model_v2</i></h3>
                <input
                    id="alphafoldinput"
                    placeholder="AF-Q76EI6-F1-model_v2"
                    type="text"
                    className="smallTextBox"
                    maxLength="40"
                />
                <Button onClick={handleAdd}>
                    Add
                </Button>
            </>

        )
    } else if (source == "localjson") {
        return (
            <>
                <UploadJSON value3={value3} onChange={(value3) => handleChange(value3)}/>
                <Button id = "addjson" onClick={handleAdd}>
                    Add
                </Button>
            </>
        )
        //sadfsafsdf

    } else if (source == "localstructure") {
        return (
            <>
                <UploadStructure value2={value2} onChange={(value2) => handleChange2(value2)}/>
                <Button onClick={handleAdd}>
                    Add
                </Button>
            </>
        )

    } else if (source == "moltube") {
        return (
            <>
                <h3>Enter your moltube id, e.g. <br></br> <i>2f206448-4fe5-44e9-bc2c-1403250677d0</i></h3>
                <input
                    id="moltubeinput"
                    placeholder="2f206448-4fe5-44e9-bc2c-1403250677d0"
                    type="text"
                    className="smallTextBox"
                    maxLength="40"
                />
                <Button onClick={handleAdd}>
                    Add
                </Button>
            </>
        )
    }
}

return (
    <div>
        <Tooltip title={"Load components"} arrow>
            <AddBoxIcon color="primary" id="add" onClick={handleDrawer}/>
        </Tooltip>

        <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            variant="persistent"
        >
            <div style={{'width': '95vw'}}>
            <Button onClick={saveClose} variant="contained">
                Close
            </Button>


            <h3> Choose the source of your file</h3>
            <div>
                {sources.map(item => {
                    return (
                        <>
                            <input type="radio" value={item} id={item} name="source"
                                   onClick={(e) => handleClick(e.target.value)}/> {item}
                        </>
                    )
                })}

            </div>

            {getInputField()}

            {displaySelection()}

            {/*<h2>Help</h2>*/}
            {/*<p><b>pdb:</b>Structure from RCSB PDB database. Input of the pdb-id (a combination of 4 letters or numbers).</p>*/}
                <Button onClick={saveClose} variant="contained" className={"bottomButton"}>
                    Close
                </Button>

            </div>
        </Drawer>
    </div>
);

}
;

export default withRouter(GetComponents);
