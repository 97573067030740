import FigureCard from "./FigureCard";
import Grid from "@material-ui/core/Grid";
import "../../App.css";

//This is a group of cards displayed in a grid.

const CardList = ({ xs, md, lg, figures, searchQuery }) => {
  console.log(searchQuery);
  const filteredFigures = figures.filter((figure) => {
      //console.log(figure)
      //TODO!!!
    const keywords = figure.title + figure.description + figure.pdb;

    return keywords.toString().toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <div className="scroll">
      <Grid container spacing={3} alignItems={"stretch"}>
        {filteredFigures.map((figure, i) => (
          <Grid item xs={xs} md={md} lg={lg}>
            <FigureCard
              id={filteredFigures[i].id}
              pdb={filteredFigures[i].pdb}
              title={filteredFigures[i].title}
              description={filteredFigures[i].description}
              nickname={filteredFigures[i].nickname}
              sessionid={filteredFigures[i].sessionid}
              date={filteredFigures[i].date}
              bgcolor={filteredFigures[i].bgcolor}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default CardList;
