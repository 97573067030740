//this is a button to change the color of a chain: Color Controller
//it needs to be associated with the following function:

import React, {useState, useContext} from "react";
import {NglContext} from "./NglContext";
import { Tooltip } from "@material-ui/core";

//<ColorPicker value={color} onChange={handleChange} />

const StageColor = ({bgcolor}) => {
    const {
        ngl: {stage},
    } = useContext(NglContext);

    const [color, setColor] = useState(bgcolor);

    const handleChange = (value) => {
        setColor(value);
        stage.setParameters({
            backgroundColor: value,
        });
    };

    if (stage) {

    return (
        <Tooltip title={"change background color"}>
            <input
                type="color"
                id="colorpicker"
                value={color}
                onChange={(event) => handleChange(event.target.value)}
            ></input>
        </Tooltip>
    );
    }
    else {
        return null
    }
};

export default StageColor;
