import React, {useContext, useEffect, useState} from "react";
import "../../../css/chainList.css";
import {NglContext} from "../NglContext";
import {Button} from "@material-ui/core";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {Tooltip} from "@mui/material";
import InfoCon from "./InfoCon";


const LabelMaker = ({chainName, comp, initLabelText}) => {

    const {
        ngl: {comparray, chaindatas},
    } = useContext(NglContext);

    const component = comparray[comp]
    const chainData = chaindatas[comp]

    const [init, setInit] = useState(initLabelText);
    const [labelText, setLabelText] = useState(initLabelText);
    const [anno, setAnno] = useState(undefined);

    useEffect(() => {
        setLabelText(initLabelText)
    }, [])



    if (!(comparray && chaindatas)) {
        return null
    }

    const addLabel = () => {
        if (labelText && component && chainData) {

            if (document.getElementById(chainName)) {
                document.getElementById(chainName).remove()
            }
            const sele = chainData[chainName].selection.map(item => `(:${item})`).join(" ; ")
            const elm = document.createElement("div")
            elm.id = chainName
            elm.innerText = labelText
            elm.style.color = "black"
            elm.style.backgroundColor = chainData[chainName].color
            elm.style.padding = "1px"
            setInit(undefined)
            setAnno(component.addAnnotation(component.getCenterUntransformed(sele), elm, {offsetY: 0}))
            chainData[chainName].labelText = labelText
            component.addAnnotation(component.getCenterUntransformed(sele), elm, {offsetY: 0})

        }
    }

    const removeLabel = () => {
        if (document.getElementById(chainName)) {
            document.getElementById(chainName).remove()
            setAnno(undefined)
            setLabelText(undefined)
            setInit(undefined)
            delete chainData[chainName].labelText
        }
    }



    return (
        <div style={{'display': 'flex', 'flex-direction': 'row', 'border': 'solid grey'}}>
            {(init || anno) ? <div style={{'width': '10vw'}}>{labelText} </div> : <input
                style={{'width': '10vw'}}
                id="label"
                placeholder={"add a label text"}
                value={labelText? labelText : undefined}
                type="text"
                className="smallTextBox"
                maxLength="60"

                onChange={(event) => {
                    setLabelText(event.target.value);
                }}
            />}
            {(init || anno) ? <Tooltip title={"Remove label"}><ClearIcon onClick={removeLabel}/></Tooltip> :
                <Tooltip title={"Add label"}>< AddIcon onClick={addLabel}/></Tooltip>}
        </div>
    );

};

export default LabelMaker;
