// this is a List of all chains in a molecule where for each chain a Row.js is generated.
// this has to be given an object with all the chains.
import React, {useState, useEffect, useContext} from "react";
import {Drawer, Button, Grid, Tooltip} from "@material-ui/core";
import "../../App.css";
import {NglContext} from "./NglContext";
import {withRouter,} from 'react-router';
import {Link, useHistory} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import version from "../../data/version.json";

const InputBox = ({components, initInfo}) => {
    const {
        ngl: {stage, chaindatas},
    } = useContext(NglContext);

    const sessionId = uuidv4()

    function makeComponents(chaindatas, components) {
        if (chaindatas) {

            const ar = []
            components.map((component, index) => {
                // Object.entries(chaindatas[index]).map(item => {
                //     delete item[1].anno
                // })
                ar.push({...component, chainData: chaindatas[index]})
            })
            return ar
        }
    }

    const [info, setInfo] = useState(initInfo);
    const [open, setOpen] = useState(false);
    const [postStatus, setPostStatus] = useState(false);
    const [activeID, setactiveID] = useState(undefined);
    const [sentStatus, setSentStatus] = useState(undefined);
    const [email, setEmail] = useState(undefined)

    useEffect(() => {
        setInfo(initInfo)
    }, [open])

    const handleDrawer = () => {
        setOpen(!open);
    };

    function makeFigureData() {
        const date = new Date()
        if (stage && chaindatas && info) {
            const figuredata = {
                info: {
                    title: info.title,
                    nickname: info.author,
                    description: info.description,
                    date: date,
                    version: version.version,
                    sessionId: sessionId
                },
                stageSettings: {
                    camerasetting: stage.viewerControls.getOrientation(),
                    bgcolor: stage.parameters.backgroundColor
                },
                components: makeComponents(chaindatas, components),
            }

            return figuredata
        }

    }

    const sendCredentials = async () => {
        if (!info.title || !(email.includes("@")) || !(email.includes(".")) || email.length < 6) {
            alert("Enter a valid email address!")
            return
        }

        async function postCredentials() {

            const res = fetch("https://figure3d.herokuapp.com/sendCredentials", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        nickname: info.author,
                        description: info.description,
                        title: info.title,
                        password: activeID[1],
                        sessionId: activeID[0],
                        recipient: email
                    }),
                    mode: "no-cors",
                },
                console.log(`Email sending.... ${email}`)
            ).then((res) => {
                if (res.status === 200) {
                    alert("Email sent successfully!")
                } else {
                    alert("Email could not be sent")
                }
            }).catch(err => {
                console.log("error:", err);
            });
        }

        postCredentials();

    }

    const publish = async () => {
            if (!info.title || !info.description || !info.author) {
                alert("To publish, you must enter a title, a description and a name")
                return
            }

            async function postFigure(stage) {
                const imageBlob = await stage.makeImage({
                    factor: 1,
                    antialias: false,
                    trim: false,
                    transparent: true,
                });

                console.log("made imageBlob");

                const now = new Date();

                const formData = new FormData();
                formData.append("imageBlob", imageBlob, sessionId);
                const response = await fetch(
                    "https://figure3d.herokuapp.com/figureimage",
                    {
                        method: "POST",
                        body: formData,
                        mode: "no-cors",
                    }
                );
                setSentStatus("true")
                const res = fetch("https://figure3d.herokuapp.com/figuremaker", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(makeFigureData())
                    }
                ).then((res) => {
                    if (res.status === 200) {
                        res.json().then(json => {
                            setactiveID(json)
                        })
                        setPostStatus(200);
                    } else {
                        setPostStatus(0);
                    }
                });
            }

            postFigure(stage);
        }
    ;
    const saveClose = () => {
        setOpen(!open);
        setPostStatus(false)
        //also needs to save to local Storage
    };

    useEffect(() => {
        setOpen(open);
    }, [open]);

    useEffect(() => {
    }, [activeID]);


    if (!postStatus) {
        return (
            <div>
                <Tooltip title={"download json"} arrow>
                    <DownloadIcon color="primary" onClick={handleDrawer}/>
                </Tooltip>

                <Tooltip title={"publish figure"} arrow>
                    <CloudUploadIcon color="primary" id="publishfigure" onClick={handleDrawer}/>
                </Tooltip>


                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    variant="persistent"
                >
                    <div style={{'width': '95vw'}}>
                        <Button onClick={saveClose} variant="contained">
                            Close
                        </Button>
                        <h3>Title</h3>
                        <input
                            id="title"
                            placeholder={initInfo ? initInfo.title : "Please enter a title"}
                            value={info ? info.title : undefined}
                            type="text"
                            maxLength="40"
                            onChange={(event) => {
                                setInfo({...info, title: event.target.value});
                            }}
                            style={{
                                width: "100%",
                                color: "black",
                                resize: "none",
                                height: "3rem",
                                "font-size": "1rem"
                            }}
                        />
                        <h3>Description</h3>
                        <textarea
                            id="description"
                            placeholder={(initInfo && info) ? initInfo.description : "Enter a description"}
                            value={info ? info.description : undefined}
                            type="text"
                            maxLength="500"
                            rows="20"
                            onChange={(event) => {
                                setInfo({...info, description: event.target.value});
                            }}
                            style={{
                                width: "100%",
                                color: "black",
                                resize: "none",
                                height: "3rem",
                                "font-size": "1rem"

                            }}
                        ></textarea>
                        <h3>Author</h3>
                        <input
                            id="author"
                            placeholder={(initInfo && info) ? initInfo.author : "Enter a name"}
                            value={info ? info.author : undefined}
                            type="text"
                            maxLength="60"
                            onChange={(event) => {
                                setInfo({...info, author: event.target.value});
                            }}
                            style={{
                                width: "100%",
                                color: "black",
                                resize: "none",
                                height: "3rem",
                                "font-size": "1rem"
                            }}
                        />
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Button id="publishnow" onClick={publish} variant="contained">
                                Publish now!
                            </Button>

                            <a
                                type="button"
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                    JSON.stringify(makeFigureData())
                                )}`}
                                download="moltube.json"
                            >
                                {`Download Json`}
                            </a>
                        </Grid>
                        <Button onClick={saveClose} variant="contained">
                            Close
                        </Button>
                    </div>
                </Drawer>
            </div>

    );
    } else if (sentStatus && !postStatus)
        {
            return <h1>Request just got sent</h1>
        }
    else if (postStatus === 200 && !activeID)
        {
            return <h1>waiting for active ID</h1>
        }
    else if (postStatus === 200 && activeID)
        {
            return (
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    variant="persistent"
                >
                    <Button onClick={saveClose} variant="contained">
                        Close
                    </Button>
                    <h3>Thank you for posting your figure!</h3>
                    <h3>You find your figure here:</h3>
                    <Link to={`/view/${activeID[0]}`}>Go to figure</Link>
                    <h3>You find your password here:</h3>
                    <h3>{activeID[1]}</h3>
                    -----<b/>
                    You yould like to get this data via email? <b/>
                    Enter your email here <b/>(it will be only used for sending the email <b/>and it won't be saved):
                    <input
                        id="title"
                        placeholder={initInfo ? initInfo.title : "yourname@email.com"}
                        type="text"
                        maxLength="20"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <Button onClick={sendCredentials}>Click to Send Email</Button>
                    <p><b/><b/></p>
                    {/*<Button onClick={saveClose} variant="contained">*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                </Drawer>
            )

        }
    else if (postStatus == 0)
        {
            return (
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    variant="persistent"
                >
                    <Button onClick={saveClose} variant="contained">
                        Close
                    </Button>
                    <h3>Something went wrong!</h3>
                </Drawer>
            )
        }

    };

    export default withRouter(InputBox);