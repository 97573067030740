//this contains the name of the chain.

import React from "react";
import "../../../css/chainList.css";

const ChainCon = ({ description, chainName }) => {
  return (
    <div className="chainCon">
     {description}
    </div>
  );
};

export default ChainCon;
