import React, { useState, useContext } from "react";
import Slider from "@material-ui/core/Slider";
import { NglContext } from "../NglContext";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const TransConALL = ({ selection, comp}) => {
  const {
    ngl: { stage, chaindatas },
  } = useContext(NglContext);

  const chainData=chaindatas[comp]

  const useStyles = makeStyles({
    root: {
      width: "10vw"
    },
  });
  const classes = useStyles();
  const [transparency, setTransparency] = useState(1);

  const handleChange = (value) => {
    value = value / 100;
    selection.forEach(
    chainName => {
      stage.getRepresentationsByName(chainName).setParameters({ opacity: value });
      chainData[chainName].opacity = value.toString();
    }

  )
  };

  return (
    <div className={classes.root}>
      <Tooltip title={"apply transparency to selection in bulk"}>
      <Slider
        defaultValue={100}
        onChange={(e, val) => handleChange(val)}
        max={100}
        min={0}
      />
    </Tooltip>
    </div>
  );
};

export default TransConALL;
