import React, {useContext, useState} from "react";
import "../../../css/chainList.css";
import {NglContext} from "../NglContext";
import {Button} from "@material-ui/core";
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';


const CenterChain = ({ chainName, comp }) => {

    const {
        ngl: { comparray, chaindatas },
    } = useContext(NglContext);

    const component = comparray[comp]
    const chainData = chaindatas[comp]

    const handleClick = () => {
        if (component && chainData){

            const sele = chainData[chainName].selection.map(item=>`(:${item})`).join(" ; ")
                console.log(component.getCenter(sele))
            component.autoView(sele)
        }
    }

  //  const removeLabel = () => {
    //    component.removeAnnotation(annotation: "test")

    if (!comparray) {
        return null
    }

      return (
          <CenterFocusStrongIcon onClick={handleClick}/>
      );

};

export default CenterChain;
