import React, {useState, useContext, useEffect} from "react";
import {NglContext} from "./NglContext";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoConVol from "./chainList.components/InfoConVol";
import TitleCon from "./chainList.components/TitleCon";
import "../../App.css"

const Volume = ({comp, name, source}) => {
    const {
        ngl: { stage, comparray, chaindatas},
    } = useContext(NglContext);

    const component = comparray[comp]
    const chainData = chaindatas[comp]

    const useStyles = makeStyles({
        root: {
            width: "10vw",
        },
    });
    const classes = useStyles();

    const [transparency, setTransparency] = useState(100);
    const [bool, setBool] = useState(true);
    const [color, setColor] = useState(undefined);

    useEffect(() => {
        setColor(chainData["vol"] ? chainData["vol"].color : undefined)
        setBool(chainData["vol"] ? chainData["vol"].visible : undefined)
        setTransparency(chainData["vol"] ? chainData["vol"].opacity : undefined)
    }, [chainData]);

    const handleVisChange = () => {
        bool ? hide() : show()
    }

    const show = () => {
        setBool(true);
        stage.getRepresentationsByName(chainData.name).setVisibility(true);
        chainData["vol"].visible = "true";

    };

    const hide = () => {
        setBool(false);
        stage.getRepresentationsByName(chainData.name).setVisibility(false);
        chainData["vol"].visible = "false";
    };
    const handleTransChange = (value) => {
        value = value / 100;
        stage.getRepresentationsByName(chainData.name).setParameters({ opacity: value });
        chainData["vol"].opacity = value
    };

    const handleColorChange = (value) => {
        setColor(value);
        stage.getRepresentationsByName(chainData.name).setColor(value);
        chainData["vol"].color = value
        }

//                <VolumeRow comp={comp} color={chainData["vol"].color} visible={chainData["vol"].visible} name={name} id={chainData["vol"].id} opacity={chainData["vol"].opacity} />
        if (!chainData) {
            return <h1>Loading</h1>
        }

    return (

            <div style={{display: "flex", direction: "row", "justify-content":"center", background:"grey"}}>
                {/*<TitleCon comp={comp}/>*/}
                <b>{name}</b>
                <InfoConVol name={name}/>



                <button
                    onClick={
                        handleVisChange
                    }
                >
                    {bool ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                </button>
                <div className={classes.root}>
                <Slider
                    key={transparency}
                    defaultValue={100}
                    onChange={(e, val) => handleTransChange(val)}
                    max={100}
                    min={0}
                />
                </div>
                    <input
                        type="color"
                        id="colorpicker"
                        value={color}
                        onChange={(event) => handleColorChange(event.target.value)}
                    ></input>

            </div>



    );
};

export default Volume;
