import CardList from "./CardList";
import React, {useState, useEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button, Grid} from "@material-ui/core";
import "../../App.css";

const Gallery = ({xs, md, lg, xss, mds, lgs}) => {
    const [figures, setFigures] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => {
        const fetchFunc = async () => {
            const response = await fetch(
                "https://figure3d.herokuapp.com/figureArray"
            );
            const resJson = await response.json();
            const resInv = await resJson.reverse();
            setFigures(resInv);
        };
        fetchFunc();
    }, []);

    if (!figures) {
        return (
            <Grid container justify="center">
                <CircularProgress/>
            </Grid>
        );
    }

    return (
        <Grid
            container
            className={"landingContainer"}
        >
                <Grid item md={mds} xs={xss} lg={lgs}>
                    <input
                        class="search"
                        style={{width: "320px", display: "block", margin: "auto", color: 'white',position: "sticky","z-index": "100"}}
                        type="search"
                        value={searchQuery}
                        placeholder="&#128270; search"
                        onChange={(event) => setSearchQuery(event.target.value)}
                    />
                </Grid>


                <CardList
                    xs={xs}
                    md={md}
                    lg={lg}
                    figures={figures}
                    searchQuery={searchQuery}
                />
            </Grid>

    );
};

export default Gallery;
