import React, {useState, useEffect} from "react";
import "../../css/inputBox.css";
import {Button, Grid, TextField} from "@material-ui/core";

const Comments = ({sessionid}) => {
    const [comment, setComment] = useState(undefined);
    const [comments, setComments] = useState([]);
    const [userCommented, setUserCommented] = useState(false);

    useEffect(() => {
        const fetchFunc = async () => {
            const response = await fetch(
                "https://figure3d.herokuapp.com/getcomments/" + sessionid
            );
            const resJson = await response.json();
            const resInv = await resJson.reverse();
            setComments(resInv);
            setUserCommented(false)
        };
        fetchFunc();
    }, [userCommented, sessionid]);

    const sendComment = async () => {
        document.getElementById('comment').value = ""
        console.log("posting comment to figure " + sessionid);

        async function postComment() {
            if (comment) {
                const res = fetch("https://figure3d.herokuapp.com/postcomment", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        author: "anonymous",
                        comment: comment.content,
                        sessionid: sessionid,
                    }),
                }).then((res) => {
                    if (res.status == 200) {
                        setUserCommented(true);
                    }
                    console.log(res)
                });
                setComment(undefined)
            }
            else {
                alert("You have to write something before publishing a comment.")
            }
        }

        postComment();
    }

    const displayComments = (comments) => {
      return (comments.length>0) ? comments.map((comment, i) => (
              <div>
                  <h3>{new Date(comment.date).toDateString()}</h3>
                  <p>{comment.comment}</p>
              </div>
          )) : <p>no comments yet. be the first to comment!</p>
    }

    return (

        <div style={{'display': 'flex', 'flex-direction': 'column', 'width': '640px'}}>
            <div style={{'display': 'flex', 'flex-direction': 'column', 'width': '640px'}}>
                <h2 style={{'color':'white'}}>Comments</h2>
                <textarea
                    id = "comment"
                    style={{width: "100%", background: "#282c34", color: "white", resize: "none", "font-size":"1.5rem"}}
                    placeholder={"Please enter your comment here"}
                    label="Content"
                    rows="3"
                    onChange={(event) => {
                        setComment({...comment, content: event.target.value});
                    }}
                ></textarea>
            </div>
            <div>
                <button onClick={sendComment} variant="contained">
                    Post Comment
                </button>
            </div>

            <div>
                {displayComments(comments)}
            </div>
        </div>
    );
};

export default Comments;
