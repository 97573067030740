import React, {useEffect, useLayoutEffect, useState} from "react";
import {Stage as NglStage} from "ngl";
import "../App.css";

const Stage = ({
                   stageId,
                   components,
                   onNglSave,
                   sessionId,
                   bgColor,
                   orientation,
                   style,
               }) => {
    const [stage, setStage] = useState(undefined);
    const [stageExists, setStageExists] = useState(false);
    const [status, setStatus] = useState(undefined)

    //##################functions start
    const makeStage = () => {
        if (!stageExists) {
            console.log("NGL STAGE NOT DEFINED");
            setStage((stage) => new NglStage(stageId));
            setStageExists((stageExists) => true);
            console.log("MADE NEW STAGE");
            return stage;
        }
    };

    const rainbow = (size) => {
        var rainbow = new Array(size);

        for (var i = 0; i < size; i++) {
            var red = sin_to_hex(i, 0 * Math.PI * 2 / 3); // 0   deg
            var blue = sin_to_hex(i, 1 * Math.PI * 2 / 3); // 120 deg
            var green = sin_to_hex(i, 2 * Math.PI * 2 / 3); // 240 deg

            rainbow[i] = "#" + red + green + blue;
        }

        function sin_to_hex(i, phase) {
            var sin = Math.sin(Math.PI / size * 2 * i + phase);
            var int = Math.floor(sin * 127) + 128;
            var hex = int.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        }

        return rainbow
    }

    //##################loadfile

    const loadFile = async () => {
        stage.removeAllComponents();
        stage.setParameters({
            backgroundColor: bgColor,
        });

        // // console.log("rendering",component2.viewer.renderPending)

        const display = async (modelId, chainData, source, name, index) => {

            const getExtension = () => {
                const ext = name.split(".").at(-1)
                console.log(ext)
            }

            const component = source == "localstructure" ? await stage.loadFile(new Blob([modelId], {type: 'text/plain'}), {ext: name.split(".").at(-1)}) : await stage.loadFile(modelId);
            const center = component.getCenter()
            const centerarrray = Object.keys(center).map((key) => -center[key])
            component.setPosition(centerarrray)
            console.log(component)

            if (component.type == "structure") {

                if (chainData) {
                    console.log("CHAINDATA DETECTED")
                    for (const [key, chain] of Object.entries(chainData)) {
                        const sele = chain.selection.map(item => `(:${item} and ${chain.autoselection} and (${chain.manualselection}))`).join(" ; ")

                        component.addRepresentation(chain.depiction, {
                            sele: chain.selection.map(item => `(:${item} and ${chain.autoselection} and (${chain.manualselection}))`).join(" ; "),
                            color: chain.color,
                            opacity: chain.opacity,
                            visible: chain.visible,
                            name: chain.name,
                        });
                        if (chain.labelText) {
                            const elm = document.createElement("div")
                            elm.id = chain.name
                            elm.innerText = chain.labelText
                            elm.style.color = "black"
                            elm.style.fontSize = "1rem"
                            elm.style.backgroundColor = chain.color
                            elm.style.padding = "1px"
                            component.addAnnotation(component.getCenterUntransformed(sele), elm, {offsetY: 0})

                        }
                    }
                } else {
                    chainData = {};

                    const number = () => {
                        let i = 0
                        component.structure.eachChain((chain) => {
                            if (chain.entity.entityType === 1 || chain.entity.entityType === 0) {
                                i = i + 1
                            }
                        })
                        return i
                    }

                    const rainbowColors = rainbow(number())

                    const arr1 = []
                    component.structure.eachResidue((rp)=>arr1.push([rp.resno, rp.resname, rp.chainname]))

                    component.structure.eachChain((chain) => {
                        if (chain.entity.entityType === 1) {
                            console.log(chain)
                            component.addRepresentation("Ribbon", {
                                sele: `(:${chain.chainname} and all)`,
                                color: rainbowColors[chain.index],
                                opacity: 1,
                                visible: true,
                                name: `${index}_${chain.chainname}`,
                            });
                            const arr = arr1.filter(residues => residues[2] == chain.chainname)
                            chainData[`${index}_${chain.chainname}`] = {
                                id: chain.chainname,
                                name: `${index}_${chain.chainname}`,
                                visible: true,
                                color: rainbowColors[chain.index],
                                depiction: "Ribbon",
                                description: `${chain.entity.description} (${chain.chainname})`,
                                selection: [`${chain.chainname}`],
                                autoselection: "all",
                                manualselection: `${arr[0][0]}-${arr[arr.length-1][0]}`,
                                opacity: 1,
                                labelText: undefined,
                            };
                        } else if (chain.entity.entityType === 0) {
                            console.log(chain.index)
                            component.addRepresentation("Spacefill", {
                                sele: `:${chain.chainname}`,
                                color: rainbowColors[chain.index],
                                opacity: 1,
                                visible: true,
                                name: `${index}_${chain.chainname}`,
                            });

                            chainData[`${index}_${chain.chainname}`] = {
                                id: chain.chainname,
                                name: `${index}_${chain.chainname}`,
                                visible: true,
                                color: rainbowColors[chain.index],
                                depiction: "Spacefill",
                                description: `${chain.entity.description} (${chain.chainname})`,
                                selection: [`${chain.chainname}`],
                                autoselection: "all",
                                manualselection: "",
                                opacity: 1,
                                labelText: undefined,
                            };
                        }
                    })
                }

            } else if (component.type == "volume") {

                if (chainData) {
                    console.log("CHAINDATA DETECTED for volume!")
                    component.addRepresentation("surface", {
                        color: chainData["vol"]["color"], name: name, opacity: chainData["vol"]["opacity"], opaqueBack: false, visible: chainData["vol"]["visible"],
                    })
                } else {
                    component.addRepresentation("surface", {
                        color: "red", name: name, opacity: 1, opaqueBack: false, visible: true,
                    })

                    chainData = {}
                    chainData["vol"] = {
                        name: name,
                        visible: true,
                        color: "#ff0000",
                        depiction: "surface",
                        opacity: 1,
                        description: name
                    }
                }}

                stage.autoView()
                return [component, chainData]
            }


            const comps = await Promise.all(
                components.map((component, index) => display(component.id, component.chainData, component.source, component.name, index)
                )
            )
            const comparray = comps.map(item => item[0])
            const chaindatas = comps.map(item => item[1])

            console.log("chaindatas:", chaindatas)

            if (orientation) {
                stage.viewerControls.orient(orientation.elements)
                console.log("orienting")
            } else {
                stage.autoView()
                console.log("auto")
            }
            setStatus("done...")

            onNglSave?.({stage, comparray, chaindatas});
        };

        useEffect(() => {
            async function fetchStage() {
                await makeStage();
                setStatus("Preparing stage for you...")
            }

            fetchStage();
        }, [stageExists]);

        useEffect(() => {
            setStatus("Loading files...")

            async function fetchComponent() {
                if (stageExists) {
                    await loadFile(stage);
                }
                setStatus(undefined)
            }

            fetchComponent();
        }, [sessionId, stageExists]);


        const handleStageResize = () => {
            stage.handleResize();
        }

        useEffect(() => {
            if (stageExists) {
                stage.handleResize();
                window.addEventListener("resize", handleStageResize);
                return () => window.removeEventListener("resize", handleStageResize);
            }
        });

        return (
            <div className={stageId}>

                <div id={stageId}></div>
                <p className={"blinking"}>{status}</p>

            </div>

        );
    };

    export default Stage;
