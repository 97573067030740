import React from "react";

const UploadJSON = (props) => {
    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
          props.onChange(JSON.parse(e.target.result))
        };
    };
    return (
        <>
            <input id="uploadjson" type="file" accept=".json" onChange={handleChange} value={props.value} />
        </>
    );
}

export default UploadJSON;
