//this is a button to change the color of a chain: Color Controller
//it needs to be associated with the following function:

import React, {useState, useContext, useEffect} from "react";

import { NglContext } from "../NglContext";

const ColCon = ({ chainName, initcolor, comp }) => {
  const {
    ngl: { stage, chaindatas },
  } = useContext(NglContext);
  const chainData = chaindatas[comp]

  const [color, setColor] = useState(initcolor);

  const handleChange = (value) => {
    setColor(value);
    stage.getRepresentationsByName(chainName).setColor(value);
    chainData[chainName].color = value;
  };

  useEffect(() => {
      setColor(initcolor)
  },[initcolor]);

  if (stage) {
  }
  return (
    <div>
      <input
        type="color"
        id="colorpicker"
        value={color}
        onChange={(event) => handleChange(event.target.value)}
      ></input>
    </div>
  );
};

export default ColCon;
